import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Container from '@mui/material/Container';
import CardIcon from '../Cards/CardIcon';
import QuoteDialogAccept from './QuoteDialog/QuoteDialogAccept';
import QuoteDialogReject from './QuoteDialog/QuoteDialogReject';
import ToolboxQuote from '../Toolbox/ToolboxQuote';
import { List as list } from '../../Styles/Base/Page';
import LoaderOverlay from '../Loader/LoaderOverlay';
import ModalAjaxError from '../Modals/ModalAjaxError';
import CardStack from '../Cards/CardStack';
import  InformationAndCoverage from "../InformationAndCoverage/InformationAndCoverage"

const Quote = props => {
  const {
    grossPremium,
    grossPremiumRate,
    onCreateNewPolicy,
    onRejectPolicy,
    onDialogClose,
    commission,
    totalSumInsured,
    onDownload,
    pricingReport,
    quoteDocument,
    customerName,
    title,
    loaded,
    dialogAcceptOpen,
    dialogRejectOpen,
    inceptionDate,
    expiryDate,
    status,
    coverages,
    uiState,
    currency,
    issuer,
    intermediary,
    description
  } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(6),
    },
    divider: {
      opacity: 0.15,
    },
    list: list(),
    stripe: {
      backgroundColor: theme.palette.stripe,
      padding: `8px 14px`,
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    stripeTitle: {
      fontSize: 18,
      display: 'inline-block',
      marginRight: theme.spacing(3),
      fontWeight: 700
    },
    link: {
      textDecoration: 'underline',
    },
    card: {
      padding: theme.spacing(2.5),
      backgroundColor: theme.palette.common.white,
      minHeight: 100,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 12,
      boxShadow: '0px 3px 12px 0px #0000001F',
      flex: 1,
    },
    cardItem: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        padding: '8px 18px',
      }
    },
    main: {
      paddingTop: theme.spacing(36/8),
      paddingBottom: theme.spacing(36/8),
    },
    blockContainer: {
      margin: theme.spacing(-0.5),
      marginBottom: theme.spacing(8),
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
      }
    },
    blockCol: {
      padding: theme.spacing(0.5),
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        width: '50%',
      }
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Toolbar/>
        <ToolboxQuote
          loaded={loaded}
          label={`Quote - ${title}`}
          variant="created"
          onDialogOpen={props.onDialogOpen}
          uiState={uiState}
          status={status}
        />
        <Container className={classes.main} maxWidth="xl">
          <Stack spacing={2}>
            <Box sx={{mb: '16px !important'}}>
              <Typography variant="h5">
                  <Box component="span" fontWeight="fontWeightBold"
                  >Quotation Summary</Box>
              </Typography>
            </Box>
            <Grid container columnSpacing={2} rowGap={2} sx={{ mx: '-8px !important' }}>
              <Grid item xs={12} md={6} display="flex">
                <CardIcon
                  reportId={quoteDocument}
                  loaded={true}
                  title="Quote document"
                  handleDownload={onDownload}
                  linkUrl={`${window.appConfig.apiUrl}/internal/files/${quoteDocument}`}
                  icon={<TrendingUpIcon/>}>
                  Coverage factsheet can be used to review the possible performance of a coverage.
                </CardIcon>
              </Grid>
              <Grid item xs={12} md={6} display="flex">
                <CardIcon
                  reportId={pricingReport}
                  loaded={true}
                  title="Aggregated pricing report"
                  handleDownload={onDownload}
                  linkUrl={`${window.appConfig.apiUrl}/internal/files/${pricingReport}`}
                  icon={<BarChartIcon/>}>
                  Coverage contains a loss and risk breakdown of a coverage before creating a quotation.
                </CardIcon>
              </Grid>
            </Grid>

            {/* Blocks */}
            <Grid container columnSpacing={2} rowSpacing={2} sx={{ mx: '-8px !important' }}>
              <Grid item xs={12} md={6} lg={3}>
                <CardStack
                  title="Gross Premium"
                  content={grossPremium}
                  currency={currency}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardStack
                  title="Gross Premium Rate"
                  content={grossPremiumRate}
                  currency={''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardStack
                  title="Total Sum Insured"
                  content={totalSumInsured}
                  currency={currency}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardStack
                  title="Commission"
                  content={commission ?? '---'}
                  currency={''}
                />
              </Grid>
            </Grid>
            {/* Blocks ./end */}

            {/* Card: Basic information */}
            <InformationAndCoverage
              customerName={customerName}
              inceptionDate={inceptionDate}
              expiryDate={expiryDate}
              grossPremium={grossPremium}
              coverages={coverages}
              title={title}
              status={status}
              currency={currency}
              issuer={issuer?.name}
              description={description}
              intermediary={intermediary}
              grossPremiumRate={grossPremiumRate}
              type="Quote"
            />
          </Stack>
        </Container>
      </Box>

      {/*Dialogs*/}
      <QuoteDialogAccept
        open={dialogAcceptOpen}
        onCreateNewPolicy={onCreateNewPolicy}
        onDialogClose={onDialogClose}
      />
      <QuoteDialogReject
        open={dialogRejectOpen}
        onRejectPolicy={onRejectPolicy}
        onDialogClose={onDialogClose}
      />
      {/*Dialogs ./end*/}

      <LoaderOverlay open={props.backdropOpen} />
      {props.ajaxError && (
        <ModalAjaxError
          open={props.ajaxError}
          handleClose={() => props.onDialogClose("ajaxError")}
        />
      )}
    </>
  );
};

export default Quote;
