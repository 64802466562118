import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import maplibre from "maplibre-gl";
import { get, useFormContext, useWatch } from "react-hook-form";
import "maplibre-gl/dist/maplibre-gl.css";
import { useFeatures } from "../../../../hooks/features";
import { useCoverages, useCoveragesByLocation } from "../../../../hooks/coverages";
import { getCentroid } from "../../../../Util/getCentroid";
import { useReverseGeocoding } from "../../../../hooks/geoapify/useReverseGeocoding";
import { CENTER_MARKER } from "./Marker";
import { useDebounce } from "../../../../hooks/utils/useDebounce";
import { GeocodingAutocomplete } from "../../../GeocodingAutocomplete/GeocodingAutocomplete";
import formatMissingValue from "../../../../Util/formatMissingValue";
import dayjs from "dayjs";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import useSupercluster from "use-supercluster";
import { getCode } from "country-list";

const MAP_ZOOM = 11;
const LAT_OFFSET = 0.05;
const MIN_ZOOM = 11;
const ITEM_PER_PAGE = 10;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: theme.spacing(10 / 8),
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  coverageTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#252733",
  },
  coverageDetails: {
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 400,
    marginTop: 5,
    lineHeight: "19px",
    color: "#1A1A21",
  },
  coveragePeriod: {
    fontSize: 16,
    fontWeight: 400,
    whiteSpace: "nowrap",
    lineHeight: "21px",
  },
  location: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 8,
  },
  checkbox: {
    color: theme.palette.primary.blue,

    "&.Mui-checked": {
      color: theme.palette.primary.blue,
    },
  },
  locationMap: {
    position: "relative",
    height: 600,
    paddingBottom: 20,
    width: "100%",
  },
  locationMapTarget: {
    width: "50%",
  },
  map: { width: "100%", height: 600 },
  search: {
    position: "absolute",
    top: 10,
    left: 10,
    width: 300,
    zIndex: 3,
    background: "#fff",
    borderRadius: 4,
  },
  button: {
    boxShadow: "none",
    padding: "4px 12px",
    backgroundColor: "#1A1A21",
    fontSize: 12,
  },
  coverages: {
    height: "100%",
    width: "50%",
    background: "#fff",
    zIndex: 3,
    marginRight: "-50%",
    transition: "all 500ms ease-in-out",
    flexGrow: 1,
  },
  marker: {
    cursor: "pointer",
  },
  activeMarker: {
    filter: "drop-shadow(0px 2px 5px #98221A)",
  },
  coverageHeading: {
    minHeight: 70,
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#EEEEEE",
    padding: "0 12px",
    margin: "0 -12px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "20px",
    color: "#000000",
  },
  coverageList: {
    margin: `0 12px`,
  },
  coverageGroup: {
    borderRadius: "8px",
    border: "1px solid #DFE0EB",
    padding: "8px 16px",
    height: "100px",
  },
  selectedTarget: {
    marginRight: "0",
  },
  buttonDetails: {
    backgroundColor: "transparent",
    color: "#3182CE",
    size: "12px",
    paddingLeft: 0,
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  markerContainer: {
    display: "flex",
    cursor: "pointer",
  },
  label: {
    position: "absolute",
    inset: 0,
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    position: "relative",
  },
}));

const options = {
  radius: 75,
  maxZoom: 15,
  minZoom: MIN_ZOOM,
  reduce: (acc, props) => {
    acc.count += props.count;
    const coverages = JSON.parse(acc.coverages);
    acc.coverages = JSON.stringify(coverages.concat(JSON.parse(props.coverages)));

    return acc;
  },
};

const apiKey = window.appConfig.geoapify.api.key;

const QuoteNewLocationAndCoverages = ({ country, selectionType }) => {
  const classes = useStyles();
  const [mapReady, setMapReady] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [address, setAddress] = useState("");
  const debouncedBounds = useDebounce(bounds);
  const { data: featuresData, isLoading: featuresLoading } = useFeatures(debouncedBounds);
  const [features, setFeatures] = useState(null);
  const [coverages, setCoverages] = useState([]);
  const { mutateAsync: getCoveragesCount } = useCoverages();
  const { mutateAsync: getCoverageCountByLocation } = useCoveragesByLocation();
  const [geocoding, setGeocoding] = useState(null);
  const debouncedGeocoding = useDebounce(geocoding);
  const centerMarkerRef = useRef(null);
  const { data: reverseGeocodingData } = useReverseGeocoding(debouncedGeocoding);
  const [boundsCluster, setBoundsCluster] = useState();
  const [selectedCoverages, setSelectedCoverages] = useState([]);
  const mapContainer = useRef(null);
  const maplibreRef = useRef();
  const coveragesCountRef = useRef({});
  const activeClusterIdRef = useRef("");
  const program = useWatch({ name: "program", exact: true });
  const defaultSIperUnit = useWatch({
    name: "defaultSIperUnit",
    exact: true,
  });
  const { setValue } = useFormContext();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [points, setPoints] = useState([]);
  const [zoom, setZoom] = useState(MAP_ZOOM);
  const countryCode = getCode(country);

  const { clusters } = useSupercluster({
    points,
    zoom,
    bounds: boundsCluster,
    options: {
      ...options,
    },
  });

  const getCount = useCallback(
    ({ monitoringTargetId, lon, lat }) => {
      if (typeof coveragesCountRef.current[monitoringTargetId] !== "undefined") {
        return coveragesCountRef.current[monitoringTargetId];
      }

      if (selectionType === "other") {
        return getCoverageCountByLocation({
          lon,
          lat,
        });
      } else {
        return getCoveragesCount({ targetId: monitoringTargetId });
      }
    },
    [getCoverageCountByLocation, getCoveragesCount, selectionType],
  );

  const createCenterMarker = ({ lon, lat, map }) => {
    if (centerMarkerRef.current) {
      centerMarkerRef.current.remove();
    }

    const element = document.createElement("div");
    element.innerHTML = CENTER_MARKER;
    element.style.zIndex = 1000;

    const marker = new maplibre.Marker({ draggable: true, element })
      .setLngLat({
        lon,
        lat,
      })
      .addTo(map);

    const onDragEnd = async () => {
      const lngLat = marker.getLngLat();
      setGeocoding({ lat: lngLat.lat, lon: lngLat.lng });

      map.flyTo({
        duration: 0,
        center: [lngLat.lng, lngLat.lat],
      });
    };

    marker.on("dragend", onDragEnd);

    centerMarkerRef.current = marker;
  };

  const onAddressChange = (value) => {
    if (value === null) {
      setBounds(null);
    } else {
      const latValue = get(value, "properties.lat");
      const lonValue = get(value, "properties.lon");
      const postcode = get(value, "properties.postcode") ?? get(value, "properties.address_line1");
      const city = get(value, "properties.city") ?? get(value, "properties.address_line2");

      if (
        typeof latValue === "number" &&
        typeof lonValue === "number" &&
        latValue >= -90 &&
        latValue <= 90 &&
        lonValue >= -180 &&
        lonValue <= 180
      ) {
        setValue("postcode", postcode);
        setValue("city", city);
        setAddress(get(value, "properties.formatted"));
        const map = maplibreRef.current;

        createCenterMarker({ lon: lonValue.toFixed(1), lat: latValue.toFixed(1), map });

        centerMarkerRef.current?.setLngLat({
          lng: lonValue,
          lat: latValue,
        });

        map.flyTo({
          duration: 0,
          center: [lonValue, latValue],
          zoom: MAP_ZOOM,
          minZoom: MIN_ZOOM,
        });

        const mapBounds = map.getBounds();
        const LON_OFFSET = (mapBounds._ne.lng - mapBounds._sw.lng) * 0.02;
        const LAT_OFFSET = (mapBounds._ne.lat - mapBounds._sw.lat) * 0.02;

        const topLeftLat = mapBounds._sw.lat - LAT_OFFSET;
        const topLeftLon = mapBounds._ne.lng + LON_OFFSET;
        const bottomRightLat = mapBounds._ne.lat - LAT_OFFSET;
        const bottomRightLon = mapBounds._sw.lng + LON_OFFSET;

        setBounds({
          topLeftLat: topLeftLat.toFixed(1),
          topLeftLon: topLeftLon.toFixed(1),
          bottomRightLat: bottomRightLat.toFixed(1),
          bottomRightLon: bottomRightLon.toFixed(1),
        });
      }
    }
  };

  const defaultBounds = (countryCode) => {
    const countryBounds = {
      US: [-125.001650, 24.949300, -66.932640, 49.590400],
      AU: [112.921114, -43.740482, 153.638673, -10.668185],
      IN: [68.176645, 6.747139, 97.402561, 35.504475],
      ID: [95.009331, -10.359987, 141.019444, 5.907957],
    };

    return countryBounds[countryCode];
  };

  useEffect(() => {
    const mapStyle = "https://maps.geoapify.com/v1/styles/osm-carto/style.json";
    const map = new maplibre.Map({
      container: mapContainer.current,
      style: `${mapStyle}?apiKey=${apiKey}`,
      interactive: true,
      zoom: 11,
      maxZoom: 11,
      minZoom: MIN_ZOOM,
      bounds: defaultBounds(countryCode),
    });

    const updateMap = () => {
      const b = map.getBounds();
      setBoundsCluster([
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
      ]);

      setZoom(map.getZoom());
    };

    map.on("move", () => {
      const center = map.getCenter();

      if (!centerMarkerRef.current) {
        return;
      }

      setGeocoding({ lat: center.lat.toFixed(1), lon: center.lng.toFixed(1) });

      const mapBounds = map.getBounds();
      const LON_OFFSET = (mapBounds._ne.lng - mapBounds._sw.lng) * 0.02;
      const topLeftLat = mapBounds._sw.wrap().lat - LAT_OFFSET;
      const topLeftLon = mapBounds._ne.wrap().lng + LON_OFFSET;
      const bottomRightLat = mapBounds._ne.wrap().lat - LAT_OFFSET;
      const bottomRightLon = mapBounds._sw.wrap().lng + LON_OFFSET;

      setBounds({
        topLeftLat: topLeftLat.toFixed(1),
        topLeftLon: topLeftLon.toFixed(1),
        bottomRightLat: bottomRightLat.toFixed(1),
        bottomRightLon: bottomRightLon.toFixed(1),
      });

      centerMarkerRef.current?.setLngLat({
        lon: center.lng,
        lat: center.lat,
      });
    });

    map.on("load", () => {
      setMapReady(true);
      updateMap();

      map.on("move", updateMap);
    });

    maplibreRef.current = map;

    return () => {
      setMapReady(false);
      maplibreRef.current?.remove?.();
    };
  }, [countryCode]);

  useEffect(() => {
    const map = maplibreRef.current;
    const isReady = !mapContainer.current || !map || !mapReady;

    if (isReady) {
      return;
    }

    const markers = clusters.map((cluster) => {
      const { count, coverages: jsonCoverages, cluster_id: clusterId } = cluster.properties;
      const coverages = JSON.parse(jsonCoverages);

      const markerContainer = document.createElement("div");
      markerContainer.classList.add(classes.markerContainer);

      const icon = document.createElement("img");
      icon.src = "/assets/images/green-circle-icon.svg";
      icon.classList.add(classes.icon);

      const label = document.createElement("div");
      label.classList.add(classes.label);
      markerContainer.appendChild(icon);
      markerContainer.appendChild(label);
      label.innerText = count;

      const marker = new maplibre.Marker({ element: markerContainer });

      markerContainer.addEventListener("click", () => {
        activeClusterIdRef.current = clusterId;
        setCoverages(coverages);
        setTotalPage(Math.ceil(coverages.length / ITEM_PER_PAGE));
        setPage(1);
      });

      if (activeClusterIdRef.current === clusterId) {
        markerContainer.classList.add(classes.activeMarker);
      }

      marker.setLngLat(cluster.geometry.coordinates).addTo(map);
      return marker;
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    };
  }, [
    mapReady,
    classes.marker,
    classes.activeMarker,
    classes.markerContainer,
    classes.icon,
    classes.label,
    clusters,
  ]);

  useEffect(() => {
    if (!features || features.features?.length === 0) {
      return;
    }

    const fetchPoints = async () => {
      const transformedFeatures =
        features.features
          .map(({ geometry, properties }) => ({
            coordinates: geometry.coordinates,
            monitoringTargetId: properties.monitoringTargetId || properties.monitoringTarget?.id,
          }))
          .map((item) => {
            const isNestedArray = Array.isArray(item.coordinates[0][0]);

            return {
              points: getCentroid(isNestedArray ? item.coordinates[0] : [item.coordinates]),
              monitoringTargetId: item.monitoringTargetId,
            };
          }) ?? [];

      const pointsWithCounts = await Promise.all(
        transformedFeatures.map(async (feature) => {
          const coverage = await getCount({
            monitoringTargetId: feature.monitoringTargetId,
            lon: feature.points[0],
            lat: feature.points[1],
          });

          return {
            type: "Feature",
            properties: {
              monitoringTargetId: feature.monitoringTargetId,
              cluster: false,
              count: coverage?.numberOfElements,
              coverages: JSON.stringify(coverage?.content),
            },
            geometry: {
              type: "Point",
              coordinates: feature.points,
            },
          };
        }),
      );

      setPoints(pointsWithCounts);
    };

    fetchPoints();
  }, [features, getCount]);

  useEffect(() => {
    setBounds(null);
  }, [program]);

  useEffect(() => {
    const coveragesConvert = selectedCoverages.map((coverage) => {
      const { limit, minPayout, riskType } = coverage;

      const payoutValue = minPayout.amount;
      const payoutCurrency = minPayout.currency;
      const limitValue = limit.amount;
      const limitCurrency = limit.currency;
      const splitedRiskType = riskType.split(">");
      const coverageName = splitedRiskType[splitedRiskType.length - 1] + " Stress Cover";
      return {
        ...coverage,
        quantity: 1,
        sumInsuredIndividual: defaultSIperUnit,
        sumInsured: 1 * defaultSIperUnit,
        name: coverageName,
        minPayout: {
          value: payoutValue,
          currency: payoutCurrency,
        },
        limit: {
          value: limitValue,
          currency: limitCurrency,
        },
      };
    });

    setValue("coveragesSelected", coveragesConvert);
  }, [coverages, defaultSIperUnit, setValue, selectedCoverages]);

  useEffect(() => {
    const storedSelectedCoverages = localStorage.getItem("selectedCoverages");
    if (storedSelectedCoverages) {
      setSelectedCoverages(JSON.parse(storedSelectedCoverages));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedCoverages", JSON.stringify(selectedCoverages));
  }, [selectedCoverages]);

  useEffect(() => {
    if (!reverseGeocodingData) {
      setAddress("");
      return;
    }

    const newAddress = reverseGeocodingData.features[0]?.properties?.formatted;
    setAddress(newAddress);
  }, [reverseGeocodingData]);

  useEffect(() => {
    if (featuresLoading) {
      return;
    }

    setFeatures(featuresData);
  }, [featuresData, featuresLoading]);

  useEffect(() => {
    const map = maplibreRef.current;

    if (!map) {
      return;
    }

    map.setMinZoom(address ? MIN_ZOOM : 0);
  }, [address]);

  const renderCoverage = (coverage, mode) => {
    return (
      <Stack direction="row" key={coverage.id} className={classes.coverageGroup}>
        <Box flexGrow={1}>
          <Typography variant="body1" className={classes.coverageTitle}>
            {coverage.indexDefinition.output.name}
          </Typography>
          <Typography variant="body1" className={classes.coverageDetails}>
            {coverage.label}
          </Typography>
          <Typography variant="body1" component="p" className={classes.coverageDetails}>
            {formatMissingValue(
              parseInt(coverage.trigger * 100) / 100 +
                " " +
                coverage.triggerUnit +
                " pays " +
                parseInt(coverage.minPayout.amount * 100) / 100 +
                " %" +
                (coverage.payoutPerUnit?.amount
                  ? ", then " +
                    parseInt(coverage.payoutPerUnit?.amount * 100) / 100 +
                    "% per " +
                    coverage.triggerUnit
                  : ""),
            )}
          </Typography>
          <Button
            className={classes.buttonDetails}
            variant="contained"
            component={RouterLink}
            to={`/coverages/coverage/${coverage.id}`}
            disableElevation
          >
            Detail
          </Button>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography component="p" className={classes.coveragePeriod}>
            {dayjs(coverage.start).format(`DD-MMM'YY`)} to {dayjs(coverage.end).format(`DD-MMM'YY`)}
          </Typography>
          {mode === "SELECTED" ? (
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={() => {
                setSelectedCoverages((prevState) =>
                  prevState.filter((item) => item.id !== coverage.id),
                );
              }}
            >
              Remove
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={() => {
                setSelectedCoverages((prevState) => [...prevState, coverage]);
              }}
            >
              Select
            </Button>
          )}
        </Box>
      </Stack>
    );
  };

  const startIndex = (page - 1) * ITEM_PER_PAGE;
  const endIndex = startIndex + ITEM_PER_PAGE;
  const coveragesList =
    coverages
      .slice(startIndex, endIndex)
      ?.filter((coverage) => !selectedCoverages.find((item) => item.id === coverage.id)) ?? [];

  return (
    <Box position="relative">
      <Box minHeight="500px" position="relative" overflow="hidden" display="flex">
        <Box className={classes.search}>
          <Typography className={classes.addressInput} component="div">
            <GeocodingAutocomplete
              address={address}
              placeholder="Search by location, district or sub-district"
              onChange={onAddressChange}
              countryCode={countryCode}
            />
          </Typography>
        </Box>
        <Box className={clsx(classes.locationMap, coverages.length && classes.locationMapTarget)}>
          <Box ref={mapContainer} className={classes.map} />
        </Box>
        <Box className={clsx(classes.coverages, coverages.length && classes.selectedTarget)}>
          <Stack spacing={2} mx={1.5} useFlexGap>
            <Typography variant="h6" className={clsx(classes.coverageHeading)}>
              {coveragesList.length} coverages{coveragesList.length > 1 ? "s" : ""} found
            </Typography>
            <Stack
              spacing={1}
              maxHeight="250px"
              overflow="auto"
              divider={<Divider orientation="horizontal" flexItem />}
            >
              {coveragesList.map((coverage) => renderCoverage(coverage))}
            </Stack>
            {coveragesList.length > 0 && (
              <Stack spacing={2} alignItems={"flex-end"}>
                <Pagination
                  page={page}
                  count={totalPage}
                  onChange={(_, value) => {
                    setPage(value);
                  }}
                  color="primary"
                />
              </Stack>
            )}
          </Stack>
          {selectedCoverages.length > 0 && (
            <Stack spacing={2} mx={1.5} useFlexGap>
              <Typography variant="h6" fontWeight="600" fontSize="18px">
                {selectedCoverages.length} coverage{selectedCoverages.length > 1 ? "s" : ""}{" "}
                selected
              </Typography>
              <Stack
                spacing={1}
                maxHeight="215px"
                overflow="auto"
                divider={<Divider orientation="horizontal" flexItem />}
              >
                {selectedCoverages.map((coverage) => renderCoverage(coverage, "SELECTED"))}
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteNewLocationAndCoverages;
