import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useForm, FormProvider } from 'react-hook-form';
import makeStyles from "@mui/styles/makeStyles";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import QuoteNewStepper from './QuoteNewStepper';
import QuotesNewCompleteQuoteModal from '../QuotesModals/QuotesNewCompleteQuoteModal';
import { useLocation } from 'react-router-dom';

// Styles
import { List as list } from '../../../Styles/Base/Page';
import { Slider as slider } from '../../../Styles/Components/Sliders';

import { QuoteNewFooter } from './QuoteNewFooter';
import QuoteNewProgram from './QuoteNewProgram/QuoteNewProgram';
import QuoteNewCustomization from './QuoteNewCustomization/QuoteNewCustomization';
import QuoteNewTerms from './QuoteNewTerms/QuoteNewTerms';
import QuoteNewLoader from './QuoteNewLoader';
import QuoteNewReview from './QuoteNewReview/QuoteNewReview';
import QuoteNewHeader from './QuoteNewHeader';
import QuoteNewSnackbar from './QuoteNewSnackbar';
import QuoteNewAjaxError from './QuoteNewAjaxError';
import QuoteNewLocationAndCoverages from './QuoteNewLocationAndCoverages/QuoteNewLocationAndCoverages'
import { useTaxStatuses } from '../../../hooks/taxStatuses/useTaxStatues';

export const STEPS = ['Program', 'Coverages', 'Customization', 'Terms', 'Review'];


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(3),
  },

  list: list(),
  stepper: {
    position: "sticky",
    top: theme.spacing(169 / 8),
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.mobileStepper,
    [theme.breakpoints.up("sm")]: {
      top: theme.spacing(16),
    },
  },
  hide: {
    visibility: "hidden",
    position: "fixed",
    zIndex: 0,
    bottom: "100vh",
  },
  slider: slider(),
  divider: {
    marginBottom: theme.spacing(3.75),
    backgroundColor: "#EEEEEE",
  },
  label: {
    display: "inline-block",
  },
  stripe: {
    backgroundColor: theme.palette.stripe,
    padding: `8px 14px`,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  stripeTitle: {
    fontSize: 18,
    display: "inline-block",
    marginRight: theme.spacing(3),
    fontWeight: 700,
  },
  link: {
    textDecoration: "underline",
  },
  card: {
    paddingBottom: theme.spacing(3),
  },
  cardItem: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      padding: "8px 18px",
    },
  },
  autoComplete: {
    marginTop: 0,
  },
  stack: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
  },
  row: {
    margin: theme.spacing(-1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 60,
  },
  spread: {
    padding: theme.spacing(1),
  },
  spreadAside: {
    padding: theme.spacing(1),
    maxWidth: 206,
  },
  loader: {
    position: "absolute",
    bottom: 8,
    left: "50%",
    transform: "translateX(-50%)",
    display: "none",
  },
  loaderIsVisible: {
    display: "block",
  },
  toolbox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
}));

const QuoteNew = () => {
  const classes = useStyles();
  const [steps, setSteps] = useState([]);
  const [openQuote, setOpenQuote] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectionType, setSelectionType] = React.useState(undefined);
  const [country, setCountry] = React.useState(undefined);
  const [quoteName, setQuoteName] = useState('');
  const searchParams = new URLSearchParams(useLocation().search);
  const coverageId = searchParams.get('coverage');
  const { data: taxStatuses = [] } = useTaxStatuses();

  const form = useForm({
    defaultValues: {
      program: undefined,
      insuredUnit: undefined,
      sumInsuredBasis: undefined,
      defaultSIperUnit: undefined,
      coveragesUnSelected: [],
      coveragesUnSelectedStage: [],
      coveragesUnSelectedStagePage: 1,
      coveragesSelected: [],
      newQuote: { name: '' },
      termsInsuredSearch: '',
      customerName: '',
      customerId: '',
      backdropOpen: true,
      postcode: undefined,
      city: undefined,
      currency: undefined,
      newCustomer: {
        inputFields: [
          {
            id: 'organization-name',
            label: 'Oraganization name',
            error: false,
            type: 'text',
            value: '',
            required: true,
          },
          {
            id: 'contact-name',
            label: 'Contact name',
            error: false,
            type: 'text',
            value: '',
            required: true,
          },
          {
            id: 'email',
            label: 'Email (contact person)',
            type: 'email',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'phone-number',
            label: 'Phone',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'tax-status',
            label: 'Tax status',
            type: 'select-one',
            error: false,
            value: '',
            required: true,
            options: []
          },
          {
            id: 'address',
            label: 'Address',
            type: 'address',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'street',
            label: 'Street',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'houseNumber',
            label: 'House number',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'postcode',
            label: 'Post code',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'city',
            label: 'City',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'district',
            label: 'District',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'county',
            label: 'County',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'state',
            label: 'State',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'stateCode',
            label: 'State code',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'country',
            label: 'Country',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'countryCode',
            label: 'Country code',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
        ],
        noValidationErrors: false,
        isLoading: false,
      },
      newIntermediary: {
        inputFields: [
          {
            id: 'organization-name',
            label: 'Oraganization name',
            error: false,
            type: 'text',
            value: '',
            required: true,
          },
          {
            id: 'contact-name',
            label: 'Contact name',
            error: false,
            type: 'text',
            value: '',
          },
          {
            id: 'phone-number',
            label: 'Phone',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'email',
            label: 'Email (contact person)',
            type: 'email',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'address',
            label: 'Address',
            type: 'address',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'street',
            label: 'Street',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'houseNumber',
            label: 'House number',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'postcode',
            label: 'Post code',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'city',
            label: 'City',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'district',
            label: 'District',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'county',
            label: 'County',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'state',
            label: 'State',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'stateCode',
            label: 'State code',
            type: 'text',
            error: false,
            value: '',
            required: false,
          },
          {
            id: 'country',
            label: 'Country',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
          {
            id: 'countryCode',
            label: 'Country code',
            type: 'text',
            error: false,
            value: '',
            required: true,
          },
        ],
        noValidationErrors: false,
        isLoading: false,
      },
      termsIntermediarySearch: '',
      intermediaryName: '',
      intermediaryId: '',
      remarks: '',
      commission: 0.125,
      formHasChanged: true,
      snackbarOpen: false,
      snackbarMessage: '',
      ajaxError:  false,
      serviceFee: 100,
    },
  });

  const [program, insuranceProgram, postcode, city] = form.watch([
    "program",
    "insuranceProgram",
    "postcode",
    "city",
  ]);

  const handleStartQuote = () => {
    setOpenQuote(true);
  };
  
  const handleAlertClose = (object, reason) => {
    if (reason !== "backdropClick") {
      setOpenQuote(false);
    }
  };

  useEffect(() => {
    if (coverageId) {
      setActiveStep(2);
    }
  }, [coverageId]);

  useEffect(() => {    
    if (!!program || !!insuranceProgram || !!postcode || !!city) {
      setQuoteName(`${program} ${insuranceProgram} ${postcode} ${city}`)
    }
  }, [program, insuranceProgram, postcode, city]);

  useEffect(() => {
    setSteps([
      { key: "program", label: "Program", isDisabled: !!coverageId, hasBack: false },
      { key: "coverages", label: "Coverages", isDisabled: !!coverageId, hasBack: !coverageId },
      { key: "customization", label: "Customization", isDisabled: false, hasBack: !coverageId },
      { key: "terms", label: "Terms", isDisabled: false, hasBack: true },
      { key: "review", label: "Review", isDisabled: false, hasBack: true },
    ]);
  }, [coverageId])

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeStep]);

  useEffect(() => {
    if (taxStatuses.length > 0) {
      const newCustomer = form.getValues("newCustomer");
      const taxStatusField = newCustomer.inputFields.find((item) => item.id === "tax-status");
      taxStatusField.options = taxStatuses.map((item) => ({ id: item.id, name: item.name }));
      form.setValue("newCustomer", newCustomer);
    }
  }, [taxStatuses, form])

  const STEPS = [
    <Container maxWidth="sm">
      <QuoteNewProgram
        onChangeActiveStep={setActiveStep}
        onChangeSelectionType={setSelectionType}
        country={country}
        onChangeCountry={setCountry}
      />
    </Container>,
    <Container maxWidth="lg">
      <QuoteNewLocationAndCoverages
        country={country}
        selectionType={selectionType}
        onChangeActiveStep={setActiveStep}
        coverageId={coverageId}
      />
    </Container>,
    <Container maxWidth="sm">
      <QuoteNewCustomization program={program} country={country}/>
    </Container>,
    <Container maxWidth="sm">
      <QuoteNewTerms country={country} quoteName={quoteName} setQuoteName={setQuoteName} />
    </Container>,
    <Container maxWidth="sm">
      <QuoteNewReview onChangeActiveStep={setActiveStep} quoteName={quoteName} />
    </Container>,
  ];

  return (
    <FormProvider {...form}>
      <Box className={classes.root}>
        <form noValidate autoComplete="off">
          <QuoteNewHeader
            activeStep={activeStep}
            handleStartQuote={handleStartQuote}
            totalSteps={steps.length - 1}
          />
          <div className={classes.stepper}>
            <Container maxWidth="sm">
              <QuoteNewStepper
                activeStep={activeStep}
                selectionType={selectionType}
                steps={steps}
              />
              <Divider className={clsx(activeStep === 1 && classes.hide, classes.divider)} />
            </Container>
          </div>
          {/* Content */}
          {STEPS[activeStep]}
          <QuoteNewFooter
            steps={steps}
            activeStep={activeStep}
            onChangeActiveStep={setActiveStep}
            selectionType={selectionType}
            handleStartQuote={handleStartQuote}
            quoteName={quoteName}
          />
          <QuotesNewCompleteQuoteModal
            open={openQuote}
            handleClose={handleAlertClose}
            quoteName={quoteName}
          />
        </form>
        <QuoteNewLoader />
        <QuoteNewSnackbar />
        <QuoteNewAjaxError />
      </Box>
    </FormProvider>
  );
};
export default QuoteNew;
